import {h, resolveComponent} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import store from "@/store";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(/* webpackChunkName: "dashboard" */ '@/views/windows/apps/Directorio.vue'),
            },
        ],
    },
    {
        path: '/apps',
        name: 'Aplicaciones',
        component: DefaultLayout,
        redirect: '/apps/directorio',
        children: [
            {
                name: 'Mis aplicaciones',
                path: '/apps/directorio',
                component: () => import('@/views/windows/apps/Directorio.vue'),
            },
            {
                name: 'Listado de aplicaciones',
                path: '/apps/listado',
                component: () => import('@/views/windows/apps/AppsListado.vue'),
            },
            {
                name: 'Editar aplicación',
                path: '/apps/edit/:id',
                component: () => import('@/views/windows/apps/AppsEditar.vue'),
            },
        ],
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: DefaultLayout,
        redirect: '/usuarios/listado',
        children: [
            {
                name: 'Listado usuarios',
                path: '/usuarios/listado',
                component: () => import('@/views/windows/usuarios/UsuariosListado.vue'),
            },
            {
                name: 'Editar usuario',
                path: '/usuarios/edit/:id',
                component: () => import('@/views/windows/usuarios/UsuariosEditar.vue'),
            },
            {
                name: 'Listado roles',
                path: '/usuarios/roles/listado',
                component: () => import('@/views/windows/usuarios/RolesListado.vue'),
            },
            {
                name: 'Editar rol',
                path: '/usuarios/roles/edit/:id',
                component: () => import('@/views/windows/usuarios/RolesEditar.vue'),
            },
        ],
    },
    {
        path: '/mi-usuario',
        name: 'Mi usuario',
        component: DefaultLayout,
        redirect: '/',
        children: [
            {
                name: 'Password',
                path: '/mi-usuario/password',
                component: () => import('@/views/windows/usuarios/UsuariosEditarPass.vue'),
            },
        ],
    },
    {
        path: '/configuration',
        name: 'Configuración',
        component: DefaultLayout,
        redirect: '/configuration/system',
        children: [
            {
                name: 'Configuración de sistema',
                path: '/configuration/sistema',
                component: () => import('@/views/windows/configuracion/NotificacionEditar.vue'),
            },
        ],
    },
    {
        path: '/',
        redirect: '/404',
        component: {
            render() {
                return h(resolveComponent('router-view'))
            },
        },
        children: [
            {
                path: '/404',
                name: 'Page404',
                component: () => import('@/views/pages/Page404'),
                meta: {
                    public: true
                }
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import('@/views/pages/Login'),
                meta: {
                    public: true
                }
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: () => import('@/views/pages/RecuperarPass.vue'),
                meta: {
                    public: true
                }
            },
            {
                path: '/reset-my-password/:token',
                name: 'ResetPasswordWToken',
                component: () => import('@/views/pages/RecuperarPass.vue'),
                meta: {
                    public: true
                }
            },
            {
                path: '/auth/manager',
                name: 'AuthManager',
                component: () => import('@/views/pages/AuthManager'),
                meta: {
                    public: true
                }
            }
            /*{
                path: '/register',
                name: 'Register',
                component: () => import('@/views/pages/Register'),
                meta: {
                    public: true
                }
            },
            ,*/
        ],
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

router.beforeEach((to, from, next) => {
    const rutaPublica = to.matched.some(record => record.meta.public);

    //console.log(to);
    if (to.path === '/404' || to.path === '/login') {
        next();
    }
    else {
        store.dispatch('ValidateLogin', {
            callback: (response) => {

                console.log(to.path);
                if (to.path === '/auth/manager' || to.path === '/reset-password' || to.path.includes('reset-my-password/')) {
                    next();
                }
                else {
                    if (typeof response.logged === 'undefined') {
                        next('/login');
                    }
                    else {
                        if (!rutaPublica) {
                            // valido si estoy logueado
                            if (typeof store.getters.authInfo.ep !== 'undefined' && store.getters.authInfo.ep && to.path !== '/mi-usuario/password') {
                                next('/mi-usuario/password');
                            }
                            else {
                                if (store.getters.authLogged) {
                                    next();
                                }
                                else {
                                    window.location.href = '/#/login';
                                }
                            }

                        }
                        else {
                            next();
                        }
                    }
                }
            }
        });
    }

    /*console.log(rutaPublica);
    return false;*/
    //const isLoginClient = to.matched.some(record => record.meta.loginClient);


});

export default router

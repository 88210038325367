<template>
  <CFooter>
    <div class="ms-auto">
      <span class="me-1 text-muted" target="_blank">Versión 1.0.1</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

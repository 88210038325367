export default [
    {
        component: 'CNavItem',
        name: 'Mis aplicaciones',
        access: 'apps/my/apps',
        to: '/apps/directorio',
        icon: 'fas fa-star',
    },
    {
        component: 'CNavGroup',
        name: 'Aplicaciones',
        access: 'Aplicaciones',
        to: '',
        icon: 'fas fa-th-large',
        items: [
            {
                component: 'CNavItem',
                name: 'Mis aplicaciones',
                access: 'apps/my/apps',
                to: '/apps/directorio',
            },
            {
                component: 'CNavItem',
                access: 'apps/admin',
                name: 'Administrar aplicaciones',
                to: '/apps/listado',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Usuarios',
        to: '',
        icon: 'fas fa-user',
        access: 'Usuarios',
        items: [
            {
                component: 'CNavItem',
                access: 'users/admin',
                name: 'Administrar usuarios',
                to: '/usuarios/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/role/admin',
                name: 'Administrar roles',
                to: '/usuarios/roles/listado',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Mi Usuario',
        to: '',
        icon: 'fas fa-user',
        access: 'Mi usuario',
        items: [
            {
                component: 'CNavItem',
                access: 'mi-usuario/password',
                name: 'Contraseña',
                to: '/mi-usuario/password',
            }
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Configuración',
        access: 'Configuración',
        to: '',
        icon: 'fas fa-cogs',
        items: [
            {
                component: 'CNavItem',
                access: 'configuration',
                name: 'Sistema',
                to: '/configuration/sistema',
            },
        ],
    },
]
